<template>
  <v-row>
    <v-col cols="12" class="pa-0 ma-0">
      <apexchart
        class="deudaGrafica"
        :type="tipo"
        :options="chartCliGeo"
        :series="dataDeudas.serie" 
        :height="this.$vuetify.breakpoint.smAndUp ? height : 400"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'DeudasGrafica',
  props:{
    datos: {
      type: Object,
      default: () => ({}),
    },
    tipo: {
      type: String,
      default: 'donut',
    },
    colorTipo:{
      type: String,
      default: 'gradient'
    },
    height: {
      type: String,
      default: 'auto',
    },
  },
  data: () => ({
    serie: [2256, 4423, 5628, 7543, 1123],

  }),
  computed: {
    dataDeudas() {
     return {
       serie: [{
         name: 'Saldo Vencido',
         data: this.datos.data ? Object.values(this.datos.data) : [],
       }],
       labels: this.datos.data ? Object.keys(this.datos.data) : [],
     }
    },
    chartCliGeo() {      
      return {
        legend: {
          show: false,
          position: 'right',
          horizontalAlign: 'center',
          fontSize: '16px',
          fontWeight: 600,
          itemMargin: {
            horizontal: 5,
            vertical: 10,
          },
          formatter: function(seriesName, opts) {
            // return [seriesName, " - ", opts.w.globals.series[opts.seriesIndex] + '$']
            return [seriesName]
          }
        },
        labels: this.dataDeudas.labels,
        grid: {
          borderColor: '#CFD8DC',
        },
        chart: {
          type: this.tipo,
          toolbar: {
            show: false
          },
        },
        height: 400,
        dataLabels: {
          show: true,
          offsetY: -20,
          formatter: (val, { w }) => {
            var numberFormat = new Intl.NumberFormat('es-ES')
            const total = w.globals.seriesTotals[0]
            const porcent = (val * 100) / total
            return [numberFormat.format(val.toFixed(1)) + '$', porcent.toFixed(2) + '%']
          },
          style: {
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 'bold',
            colors: ['#003b49'],
          },
        },
        xaxis: {

        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: this.$vuetify.breakpoint.smAndUp,
            formatter: function (val) {
              return val;
            },
            style: {
              fontSize: '9px',
            },
          },        
        },
        colors: ['#4A148C', '#009688', '#FF5722', '#E91E63', '#FFD600'],
        plotOptions: {
          bar: {
            distributed: true,
            borderRadius: 10, 
            dataLabels: {
              position: 'top', // top, center, bottom
            },           
          },

          pie: {
            donut: {
              labels: {
                show: this.total,
                name: {
                  show: true,
                  fontSize: '22px',
                  color: '#003b49',
                },
                value: {
                  show: true,
                  fontSize: '28px',
                  color: '#000',
                  fontWeight: 700,
                  formatter: function (value) {
                    return value.toLocaleString() + '$'
                  },
                },
                total: {
                  show: true,
                  fontSize: '12px',
                  color: '#607d8b',
                  label: 'TOTAL',
                  formatter: function (value) {
                    return value.globals.seriesTotals.reduce((a, b) => {return a + b}, 0)
                  },
                },
              },
            },
          },
        },
      }
    },
  },
}
</script>
<style>
.deudaGrafica .apexcharts-data-labels text tspan:nth-child(2) {
  fill: #FFF;
}
</style>
